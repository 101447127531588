import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthenticationService from "../Services/AuthenticationService";

class AuthenticatedRoute extends Component {
  render() {
    /*
    // EG27619 - Menu permission checks
    if (
      AuthenticationService.isUserLoggedIn() &&
      this.props.permissionChecker
    ) {
      if (
        this.props.permissionChecker.hasPermission(
          this.props.user,
          this.props.path
        )
      ) {
        return <Route {...this.props} />;
      } else {
        return <Redirect to="/wcpp/PageNotFound" />;
      }
    }
*/

    if (
      AuthenticationService.isUserLoggedIn() ||
      window.location.pathname === "/wcpp/home" ||
      window.location.pathname === "/wcpp/forgotusernm"
    ) {
      return <Route {...this.props} />;
    } else if (window.location.pathname === "/wcpp/confirmRegistration") {
      // return empty string becasue we do not want any parts that
      // should only display if the user is authenticated
      return null;
    } else if (
      window.location.pathname === "/wcpp/forgotpassword" ||
      window.location.pathname === "/wcpp/forgotusernm"
    ) {
      // return empty string becasue we do not want any parts that
      // should only display if the user is authenticated
      return null;
    } else if (window.location.pathname === "/wcpp/confirmAddUser") {
      // return empty string becasue we do not want any parts that
      // should only display if the user is authenticated
      return null;
    } else if (window.location.pathname === "/wcpp/registration") {
      // return empty string becasue we do not want any parts that
      // should only display if the user is authenticated
      return null;
    } else if (window.location.pathname === "/wcpp/registrationComplete") {
      // return empty string becasue we do not want any parts that
      // should only display if the user is authenticated
      return null;
    } else if (window.location.pathname === "/wcpp/confirmPasswordReset") {
      // return empty string becasue we do not want any parts that
      // should only display if the user is authenticated
      return null;
    } else if (window.location.pathname === "/wcpp/resetPassword") {
      // return empty string becasue we do not want any parts that
      // should only display if the user is authenticated
      return null;
    } else if (window.location.pathname === "/wcpp/authenticate") {
      return null;
    } else if (window.location.pathname === "/wcpp/login") {
      return <Redirect to="/wcpp/login" />;
    } else {
      return <Redirect to="/wcpp/login" />;
    }
  }
}

export default AuthenticatedRoute;
