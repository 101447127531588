import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";
import AuthenticationService from "../../Services/AuthenticationService";
import ContactUsDlg from "./../ContactUsDlg";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import * as versionDtls from "../../utils/build";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";

import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import FeeScheduleService from "../../Services/FeeScheduleService";

import NotificationService from "../../Services/NotificationService";
import LiveChat from "../LiveChat";

import RegistrationService from "../../Services/RegistrationService";
import UserProfileDlg from "../UserProfileDlg";

class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mirrorDlgOpen: false,
      feeSchedShowMenu: null,
      userNotifications: [],
      shouldShowUserProfileDialog: false,
    };
  }

  componentDidMount() {
    this.getUserNotifications();
    this.getShouldShowUserProfileDialog();
  }

  handleClickContactUs = () => {
    return <ContactUsDlg />;
  };

  handleNavHome = () => {
    this.props.history.push("/wcpp/home");
  };

  logout = () => {
    if (AuthenticationService.isUserLoggedIn()) {
      this.props.setLogout();
      AuthenticationService.logout();
      const link = document.createElement("a");
      link.href = `/wcpp/login`;
      link.click();
    }
  };

  getProfileLink = () => {
    return (
      <NavLink
        className="App-link"
        to="/wcpp/UserProfile"
        aria-label="Home Page Header Section: <User> account Hyperlink"
      >
        {this.props.user.userFirstName}&nbsp;
        {this.props.user.userLastName}'s Account
      </NavLink>
    );
  };

  handleRevert = () => {
    this.setState({ mirrorDlgOpen: true });
  };

  stopMirror = () => {
    console.log(`stoppinggg mirror`);
    this.handleClose();
    this.props.stopMirror();
    this.props.history.push("/wcpp/home");
  };
  handleClose = () => {
    this.setState({ mirrorDlgOpen: false });
  };

  handleFeeSchedCountClick = (event) => {
    this.setState({ feeSchedShowMenu: event.currentTarget });
  };

  handleFeeSchedMenuClose = () => {
    this.setState({ feeSchedShowMenu: null });
  };

  handleNotificationSelected = (event, url, userNotifications, index) => {
    this.setState({ feeSchedShowMenu: null });
    const link = document.createElement("a");
    link.href = `${url}`;

    link.setAttribute("target", "_blank");
    link.click();
    if (userNotifications.notification_user_dwnld === "Y") {
      return;
    }
    console.log(`*(****set user viewed`);
    NotificationService.setUserViewed(this.props.user.id)
      .then((response) => {
        if (response.status === 200) {
          console.log(`*(****set user viewed`);
          this.getUserNotifications();
        }
      })
      .catch((error) => {
        console.log(`Notification set Viewed error ${error}`);
      });
  };

  handleFileSelected = (event, schedUri, index) => {
    this.setState({ feeSchedShowMenu: null });
    const link = document.createElement("a");
    link.href = `${AuthenticationService.getApiUrl()}${schedUri}&usrName=${AuthenticationService.getLoggedInUserName()}&mirrorNm=${
      this.props.mirrorUserNm
    }`;

    link.setAttribute("target", "_blank");
    link.click();

    if (this.props.mirrorUserNm !== "") {
      return;
    }

    setTimeout(() => {
      FeeScheduleService.getUserFeeSchedrequests(
        AuthenticationService.getLoggedInUserName()
      )
        .then((response) => {
          if (null !== response.data) {
            this.props.setFeeSchedRqstCount(response.data.successCount);
            this.props.setFeeSchedRqstsFiles(response.data.feeSchedList);
          }
        })
        .catch((error) => {
          console.log("Error retrieving User FeeSched requests:" + error);
        });
    }, 3000);
  };
  getUserNotifications = () => {
    NotificationService.getUserNotifications(this.props.user.id)
      .then((response) => {
        this.setState({ userNotifications: response.data });
      })
      .catch((error) => {
        console.log("Error retrieving User notifications:" + error);
      });
  };

  getFeeSchedRqstsCount = () => {
    const userNotifications = this.state.userNotifications;
    const notificationCount = !userNotifications ? 0 : userNotifications.length;
    if (
      (null !== this.props.feeSchedRqstFiles &&
        this.props.feeSchedRqstFiles.length > 0) ||
      (userNotifications !== null && userNotifications.length > 0)
    ) {
      return (
        <div style={{ display: "inline-block" }}>
          <Link
            onClick={this.handleFeeSchedCountClick}
            aria-label="Opens Notifications dropdown"
            disableFocusRipple
          >
            <Badge
              badgeContent={this.props.feeSchedRqstCount + notificationCount}
              color="secondary"
            >
              <NotificationsActiveIcon />
            </Badge>
          </Link>
          <Menu
            id="simple-menu"
            anchorEl={this.state.feeSchedShowMenu}
            keepMounted
            open={Boolean(this.state.feeSchedShowMenu)}
            onClose={this.handleFeeSchedMenuClose}
          >
            {this.props.feeSchedRqstFiles.map((el, index) => {
              if (el.fileViwed) {
                return (
                  <MenuItem
                    component={Link}
                    color="secondary"
                    aria-label="Opens PDF in a new window"
                    key={el.feeSchedRqstId}
                    onClick={(event) => {
                      this.handleFileSelected(event, el.schedUri, index);
                    }}
                  >
                    {el.fileName}{" "}
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem
                    component={Link}
                    color="primary"
                    aria-label="Opens PDF in a new window"
                    key={el.feeSchedRqstId}
                    onClick={(event) => {
                      this.handleFileSelected(event, el.schedUri, index);
                    }}
                  >
                    {el.fileName}{" "}
                  </MenuItem>
                );
              }
            })}
            {userNotifications.map((el, index) => {
              if (el.notification_user_dwnld === "Y") {
                return (
                  <MenuItem
                    component={Link}
                    color="secondary"
                    aria-label="Opens PDF in a new window"
                    key={el.wcp_notification_id}
                    onClick={(event) => {
                      this.handleNotificationSelected(
                        event,
                        el.notification_link,
                        el,
                        index
                      );
                    }}
                  >
                    {el.name}{" "}
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem
                    component={Link}
                    color="primary"
                    aria-label="Opens PDF in a new window"
                    key={el.wcp_notification_id}
                    onClick={(event) => {
                      this.handleNotificationSelected(
                        event,
                        el.notification_link,
                        el,
                        index
                      );
                    }}
                  >
                    {el.name}{" "}
                  </MenuItem>
                );
              }
            })}
          </Menu>
          &nbsp;
          <span style={{ color: "black" }}>
            {this.props.feeSchedRqstCount + notificationCount > 0 ? (
              <span>&nbsp;&nbsp;</span>
            ) : (
              ""
            )}
            |
          </span>
          &nbsp;
        </div>
      );
    }
  };

  getShouldShowUserProfileDialog = () => {
    let shouldShow = false;

    RegistrationService.findShouldShowUserProfileDlg(this.props.user.userNm)
      .then((response) => {
        shouldShow = response.data;
        this.setState({ shouldShowUserProfileDialog: shouldShow });
      })
      .catch((error) => {
        console.log("Error retrieving User demographics:" + error);
      });
  };

  getUserProfileDialog = () => {
    let shouldShow = this.state.shouldShowUserProfileDialog;

    if (shouldShow) {
      return (
        <UserProfileDlg
          userName={this.props.user.userNm}
          open={true}
          onOk={(props) => {
            this.setState({ shouldShowUserProfileDialog: false });

            if (props) {
              // merge original user object with the props from the dialog and update the top level user
              this.props.updateUser({...this.props.user, ...props});
            }
          }}
        />
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <Fragment>
        <div className="Header">
        {this.state.shouldShowUserProfileDialog &&
            this.getUserProfileDialog()}

          <Dialog
            open={this.state.mirrorDlgOpen}
            onClose={!this.state.mirrorDlgOpen}
            aria-labelledby="mirror-user-dialog-title"
            aria-describedby="mirror-user-dialog-description"
          >
            <DialogTitle id="mirror-dialog-title">
              {`Are you sure you want to Revert?`}
            </DialogTitle>
            <DialogActions>
              <Button variant="text" autoFocus onClick={this.stopMirror}>
                YES
              </Button>
              <Button variant="text" onClick={this.handleClose}>
                CANCEL
              </Button>
            </DialogActions>
          </Dialog>
          {this.props.logout === true ? this.logout() : ""}

          <div
            style={{
              width: "100%",
              display: "inline-block",
              textAlign: "right",
              paddingTop: ".75rem",
              fontSize: "13px",
              paddingBottom: ".75rem",
              paddingRight: "1rem",
            }}
            className="App-ltgrey-bkgrnd"
          >
            {this.getFeeSchedRqstsCount()}

            <ContactUsDlg /> <LiveChat />
            
            <span className="Header-spacer" />
            &nbsp;<span style={{ color: "black" }}>|</span>

            <div className="profileLink">
              {" "}
              {!this.props.user ? "" : this.getProfileLink()}
            </div>
            {this.props.mirrorUserNm === "" ? (
              <span />
            ) : (
              <div className="profileLink">
                &nbsp;
                <span style={{ color: "black" }}>
                  {this.props.mirrorUsrNmDisplay}
                </span>
                <span style={{ color: "black" }}>|</span>&nbsp;
                <Link color="primary" onClick={this.handleRevert}>
                  Revert
                </Link>
              </div>
            )}
            &nbsp;<span style={{ color: "black" }}>|</span>&nbsp;
            <IconButton
              className="App-link"
              style={{ fontSize: "13px" }}
              onClick={this.logout}
              aria-label="Home Page Header Section: Sign Out Hyperlink"
              disableFocusRipple
            >
              Sign Out
            </IconButton>
          </div>
          <div style={{ textAlign: "left" }}>
            <NavLink
              to="/wcpp/home"
              className="Header-icon-link"
              aria-label="Opens Homepage in the current window"
            >
              <img
                src={require("../../images/Coventry-Provider-logo-279x58.png")}
                alt="Coventry Provider"
                style={{ width: "1", height: "1", padding: ".7em" }}
              />
            </NavLink>

            <img
              src={require("../../images/Coventry-logo-279x58.png")}
              alt="Coventry An Enlyte Company"
              style={{
                width: "1",
                height: "1",
                padding: ".7em",
                float: "right",
              }}
            />
            <p hidden>
              BE Branch: {this.props.user ? this.props.user.appVersion : "1"}
              FE Branch:{versionDtls.releaseDetails}
            </p>

            {/*  <TextField  label="BE Branch:" 
                                        defaultValue={this.props.user.appVersion} 
                                         />
                                    <TextField  label="FE Branch:" 
                                        defaultValue={versionDtls.releaseDetails} 
                                        style={{visiblity:'hidden'}}  /> */}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default header;
