import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme} from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Paper from '@material-ui/core/Paper';
import { isNotEmpty } from '../../../utils/Validator';
import {isEmpty, parseDate} from '../../../utils/Utils';
import { formatDate, currencyFormat } from '../../../utils/Format';
import "../../../table.css";
import TextField from "@material-ui/core/TextField";
import AuthenticationService from "../../../Services/AuthenticationService";
import axios from "axios";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";



const headCells = [
    { id: 'ftin', fid: 'ftin',numeric: false, disablePadding: true, label: 'Tax ID Number (TIN)', width: '4rem', filterLable:'Tax ID',sortable: true },
    { id: 'claimantName', fid: 'claimantName', numeric: false, disablePadding: true, label: 'Claimant Name', width: '6rem',filterLable:'Name', sortable: true },
    { id: 'beginServiceDate', fid: 'beginServiceDateStr',numeric: false, disablePadding: true, label: 'Begin Service Date', width: '4rem', filterLable:'Date',sortable: true },
    { id: 'endServiceDate', fid: 'endServiceDateStr',numeric: false, disablePadding: true, label: 'End Service Date', width: '4rem',filterLable:'Date',sortable: true },
    { id: 'billControlNumber', fid: 'bcnDcn', numeric: false, disablePadding: true, label: 'Bill Reference Number / DCN', filterLable:'BCN/DCN',width: '8rem', sortable: true },
    { id: 'physicianFirstName', fid: 'physcianName',numeric: false, disablePadding: true, label: 'Provider Name', width: '6rem',filterLable:'Name', sortable: true },
    { id: 'facilityName', fid: 'facilityName',numeric: false, disablePadding: true, label: 'Facility Name', width: '6rem', filterLable:'Name',sortable: true },
    { id: 'billCharges', fid: 'billChargesStr',numeric: true, disablePadding: true, label: 'Billed Charges', width: '5.5rem', filterLable:'Charges',sortable: true },
    { id: 'recommendedAllowance', fid: 'recommendedAllowanceStr',numeric: true, disablePadding: true, label: 'Recommended Allowance',filterLable:'Allowance', width: '5.5rem', sortable: true },
    { id: 'claimNumber', fid: 'claimNumber',numeric: false, disablePadding: true, label: 'Claim Number', width: '8rem', filterLable:'Claim',sortable: true },
    { id: 'vuewPayment', fid: 'vuewPayment',numeric: false, disablePadding: true, label: 'Payment Information', width: '4rem', filterLable:'ID',sortable: false }
];


function desc(a, b, orderBy) {
    let c = a[orderBy];
    let d = b[orderBy];

    if (isNotEmpty(c)) {
        if (typeof c === "string") {
            c = c.toUpperCase();
        } else if (c instanceof Date) {
            c = parseDate(c);
        }
    } else {
        c = "";
    }

    if (isNotEmpty(d)) {
        if (typeof d === "string") {
            d = d.toUpperCase();
        } else if (d instanceof Date) {
            d = parseDate(d);
        }
    } else {
        d = "";
    }

    if (d < c) {
        return -1;
    }

    if (d > c) {
        return 1;
    }

    return 0;
}

function stableSort(array, cmp) {

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function isIE() {
    return false;//(window.navigator.userAgent.match(/MSIE|Trident/) !== null);
}
function EnhancedTableHead(props) {
    const {classes, order, orderBy, onRequestSort, onRequestFilter } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const onFilter = property => event => {
        onRequestFilter(event.target.value, property);

    };

    return (
        <TableHead >

            <TableRow style={{borderBottom:'none'}}>
                {headCells.map(headCell => (


                    <TableCell

                        key={headCell.id}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ maxWidth: headCell.width, paddingBottom:0 }}
                        autoFocus={headCell.id === 'patientFirstName'}
                    >

                            <div  >
                                {headCell.sortable &&
                                <TableSortLabel
                                    style={isIE() ? { maxWidth: headCell.width} :{}}
                                    active={orderBy === headCell.id}
                                    direction={order}
                                    onClick={createSortHandler(headCell.id)}
                                >

                                        <div >
                                            {headCell.label}

                                            {orderBy === headCell.id ? (
                                                <span className={classes.visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>) : null}</div>


                                </TableSortLabel>
                                }

                                {!headCell.sortable &&
                                <div>
                                    {headCell.label}
                                </div>
                                }
                            </div>



                    </TableCell>



                ))}
            </TableRow>
            <TableRow className='filter-row' style={{borderTop:'none'}}>
                {headCells.map(headCell => (

                    <TableCell

                        key={headCell.id+1}
                        padding={headCell.disablePadding ? 'none' : 'default'}

                        style={{ maxWidth: headCell.width, paddingBottom:0,borderTop:'none', paddingTop: 0}}
                        autoFocus={headCell.id === 'patientFirstName'}
                    >
                        <div  style={{paddingRight:'.5rem',verticalAlign:'bottom'}}>
                            {headCell.id === 'vuewPayment'? '' :
                                <TextField
                                    id="filterId"
                                    fullWidth
                                    autoFocus={headCell.id === 'userFirstName'}
                                    style={isIE() ? {maxWidth: headCell.width,height:'.7'}: {height:'.7'}}
                                    placeholder={`Enter ${headCell.filterLable}`}
                                    margin="dense"
                                    variant="outlined"
                                    onChange={onFilter(headCell.fid)}/>
                            }
                        </div>

                    </TableCell>

                ))}
            </TableRow>
        </TableHead>

    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    onRequestFilter: PropTypes.func.isRequired,
};

const EnhancedTableToolbar = () => {

    return ({}
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();

    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}


const useStyles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 450,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

});

class BillResultTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            order: 'asc', orderBy: 'patientFirstName', page: 0, rowsPerPage: 10, mitchImageUrl:''
        }

    }

    showLink = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');

        setTimeout(() => {
            link.click()
        }, 3000);

    }

     precheckEor = (precheckUrl, fIdURL) => {
        console.log(`create and click link----(**(***`)
         if(isEmpty(fIdURL)) {
             this.showLink(precheckUrl);
             return;
        }
         axios.get(`${AuthenticationService.getApiUrl()}/billSearch/precheckEor`, {
                 params: {
                     url: precheckUrl
                 }

           }).then((response) => {
               console.log(`response-> ${response.statusText} `);
               this.showLink(precheckUrl);
           }).catch((error) => {
               console.log(`Error getting ICN EOR-> ${error} now open that link`)
               this.showLink(fIdURL);
          })

    }

    handleRequestFilter = (value, property) => {
        console.log(`filter value->${value} and the property->${property}`)
        if( value.length < 1){
            this.props.setFilter(this.props.billList);
            return true;
        }else{
            this.props.setFilter(this.props.billList.filter(bill => {
                let cValue = value.toUpperCase();
                let pValue = bill[property];
                if(pValue && pValue.toUpperCase().search(cValue) !== -1) {
                    this.setState({ page: 0 });
                    return bill[property];
                }
                return false;
            }))
        }

    }

    handleRequestSort = (event, property) => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';
        this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property });
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage })
    }

    handleChangeRowsPerPage = event => {
        this.setState({page: 0, rowsPerPage: event.target.value})
    }
    showBlockedImage = (url) => {
        window.open(this.state.mitchImageUrl,'_blank');

        this.setState({mitchImageUrl:''});
    }

    getTCBillImage = (billImageUrl, url) => {

        if(isEmpty(url)) {

            return;
        }
        console.log(`try to get we may need post:${url}`)
        this.props.showProgressMsg(true);
        axios.get(`${AuthenticationService.getApiUrl()}/billSearch/imageExist${url}`).then((response) => {
                this.props.showProgressMsg(false);
            console.log(`result of open try-->:${response.data}`)
                if(isNotEmpty(response.data) && response.data === true) {
                    let newTab = window.open(`${AuthenticationService.getApiUrl()}${billImageUrl}`,'_blank');

                    try {
                        newTab.focus();
                    }catch(e) {
                        console.log(`Error: ${e}`);
                    }
                } else {
                    console.log(`We did not find a URI for the bill image:${url}`)
                    this.props.updateGlobalMessage('The image cannot be displayed. Contact Coventry Provider Service for assistance.');
                }

            }).catch((error) => {
            console.log(`We did not find a URI for the bill image:${url}`)
            this.props.updateGlobalMessage('The image cannot be displayed. Contact Coventry Provider Service for assistance.');

        })

    }


    getMitchBillImage = (mitchId, bEor, exId) => {
        console.log(`create and click link----(**(***`)

        if(isEmpty(mitchId)) {

            return;
        }
        this.props.showProgressMsg(true);
        axios.get(`${AuthenticationService.getApiUrl()}/billSearch/billImage/${mitchId}/${bEor}/${exId}`)

            .then((response) => {
                this.props.showProgressMsg(false);
            if(isNotEmpty(response.data)) {
                let newTab = window.open(response.data,'_blank');

                try {
                    newTab.focus();
                } catch (e) {
                    this.setState({mitchImageUrl:response.data});
                }
            } else {
                console.log(`We did not find a URI for the bill image:${response.data}`)
                this.props.showProgressMsg(false);
                this.props.updateGlobalMessage('The image cannot be displayed. Contact Coventry Provider Service for assistance.');
            }

        }).catch((error) => {
            console.log(`Error getting Mitchell Image-> ${error} `)
            this.props.updateGlobalMessage('The image cannot be displayed. Contact Coventry Provider Service for assistance.');
        })

    }

    getBillImage = (row) => {
        if(row.mitchBill === true) {
            return(
                isNotEmpty(row.documentId) && !row.documentId.startsWith('RUSH')
               ? <Tooltip title="View Bill image " aria-label='Bill Search Results: Bill Reference Number/DCN hyperlink'>

                    <Link color='primary'
                          onClick={() => this.getMitchBillImage(row.billControlNumber, false, row.exId)}
                          aria-label='Bill Search Results: Bill Reference Number/DCN hyperlink' >{row.bcnDcn}</Link></Tooltip>
                    : <span>{row.bcnDcn}</span>
            );
        } else {
            return(
                isNotEmpty(row.billImageUrl) && isNotEmpty(row.documentId) && !row.documentId.startsWith('RUSH')
            ? <Tooltip title="View Bill image " aria-label='Bill Search Results: Bill Reference Number/DCN hyperlink'>

                <Link color='primary'
                      onClick={() => this.getTCBillImage(row.billImageUrl, row.billImageUrl.slice(row.billImageUrl.indexOf('?')))}
                      aria-label='Bill Search Results: Bill Reference Number/DCN hyperlink' >{row.bcnDcn}</Link></Tooltip>
            : <span>{row.bcnDcn}</span>
        ) ;
    }
    }
    getEorRow = (row) => {
        if (row.mcpsBill === true) {
            return (
                <Tooltip title="View Pricing Sheet" aria-label='Bill Search Results: Recommended Allowance View Pricing Sheet hyperlink'>
                    <Link color='primary'
                          onClick={(event) => {
                              this.props.handleSearchBillPriceSheet(event, row.ftin, row.billControlNumber);
                          }}
                          aria-label='Bill Search Results: Recommended Allowance View Pricing Sheet hyperlink'> {isNotEmpty(row.recommendedAllowance) ?
                        currencyFormat(row.recommendedAllowance) : 'View Pricing Sheet'}</Link></Tooltip>
            );
        }  else if (row.mitchBill === true && row.eor === true) {
            return(<Fragment>
            <Tooltip title="View EOR image " aria-label="View EOR image">

                <Link color='primary'
                      onClick={() => this.getMitchBillImage(row.billControlNumber, true, row.exId)}
                      aria-label='Bill Search Results: Recommended Allowance View EOR Image hyperlink' >
                    {isNotEmpty(row.recommendedAllowance) ? currencyFormat(row.recommendedAllowance) : 'View EOR'}</Link></Tooltip>

            </Fragment>);

        } else if (row.mitchBill !== true && row.eorImageUrl) {
            return (
                <Fragment>
                    <Tooltip title="View EOR image " aria-label='Bill Search Results: Recommended Allowance View EOR Image hyperlink'>
                        <Link color='primary' onClick={() => this.precheckEor(row.eorImageUrl, row.foreignBillIdURL)}
                              aria-label='Bill Search Results: Recommended Allowance View EOR Image hyperlink'>
                            {isNotEmpty(row.recommendedAllowance) ? currencyFormat(row.recommendedAllowance) : 'View EOR'}</Link></Tooltip>
                </Fragment>
            );
        } else {
            return(isNotEmpty(row.recommendedAllowance) ? currencyFormat(row.recommendedAllowance) : '');
        }
    }

    getPayInfo = (row) => {

        if(row.mitchBill === true) {
            return (row.billStatus !== '0' && row.billStatus !== '3' ?  'Not Available' :

                    <Link color='primary'
                          onClick={() => {this.props.handleMitchPaymentInfo(row.paidAmount, row.paidDate, row.checkNumber)}}
                          aria-label='Bill Search Results: Payment Information View Payment Info hyperlink'>View Payment Info</Link>

            );

        }else {
            return (
                row.mcpsBill === true ? 'Not Applicable' : this.getViewPmtInfo(row)

            );
        }

    }
    getViewPmtInfo = (row) => {
        if( row.billStatus !== '0' && row.billStatus !== '3') {
            return('Not Available');
        } else {
            return(<Link color='primary'
                         onClick={(event) => this.props.handleViewPaymentInfo(event, row.ftin, row.billControlNumber)}
                         aria-label='Opens Payment Info pop up as a modal window'>View Payment Info</Link>);
        }


    }

    render() {
        const { classes } = this.props;

        return (
            <div className="cvsTable">

                <p className="eor-pricing-message">
                    The amounts shown on an EOR or Network Pricing Sheet are recommended reimbursement amounts. Actual reimbursement amounts may differ depending upon compensability decisions or authorizations made by the Payor. EOR and Pricing Sheet formats and reimbursement information displayed on this portal may differ from those mailed by the Payor.
                </p>

                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                            component="div"
                            count={this.props.billsFiltered.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            ActionsComponent={TablePaginationActions}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                        {/*  <EnhancedTableToolbar numSelected={selected.length} />*/}
                        <div className={classes.tableWrapper}>
                            <Table
                                className={`${classes.table} App-font`}
                                aria-labelledby="tableTitle"
                                size='small'
                                aria-label="Bill Search Results table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    onRequestFilter={this.handleRequestFilter}
                                    rowCount={this.props.billsFiltered.length}


                                />
                                <TableBody>
                                    {stableSort(this.props.billsFiltered, getSorting(this.state.order, this.state.orderBy))
                                        .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                        .map((row, index) => {

                                            const labelId = `enhanced-table-Select-${index}`;

                                            return (
                                                <TableRow
                                                    tabIndex={-1}
                                                    key={index}
                                                    style={{
                                                        padding: '5px 20px',
                                                        height: 25,
                                                    }}
                                                >
                                                    <TableCell component="th" id={labelId} scope="row" padding="none"
                                                               size='small'>
                                                        {row.ftin}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" padding="none"
                                                               size='small'>
                                                        {row.claimantName}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" padding="none"
                                                               size='small'>
                                                        {formatDate(row.beginServiceDate)}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" padding="none"
                                                               size='small'>
                                                        {formatDate(row.endServiceDate)}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" padding="none"
                                                               size='small'>

                                                        {this.getBillImage(row)
                                                        }
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" padding="none"
                                                               size='small'>
                                                        {row.physcianName}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" padding="none"
                                                               size='small'>
                                                        {row.facilityName}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" padding="none"
                                                               size='small' align="right">
                                                        {currencyFormat( row.billCharges)}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" padding="none"
                                                               size='small' align="right">
                                                        {this.getEorRow(row)}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" padding="none"
                                                               size='small'>
                                                        {row.claimNumber}
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" padding="none"
                                                               size='small'>

                                                        {this.getPayInfo(row)}

                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}

                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                                            count={this.props.billsFiltered.length}
                                            rowsPerPage={this.state.rowsPerPage}
                                            page={this.state.page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>

                        </div>
                        <Dialog
                            open={isNotEmpty(this.state.mitchImageUrl)}
                            onClose={isEmpty(this.state.mitchImageUrl)}
                            aria-labelledby="mirror-user-dialog-title"
                            aria-describedby="mirror-user-dialog-description"
                        >
                            <DialogTitle id="mirror-dialog-title">{`Please add this site to allow popups or click VIEW IMAGE.`}
                            </DialogTitle>
                            <DialogActions>
                                <Button variant="text" autoFocus onClick={this.showBlockedImage}  >
                                    VIEW IMAGE</Button>
                                <Button variant="text" onClick={() => {this.setState({mitchImageUrl:''})}} >
                                    CLOSE</Button>
                            </DialogActions>
                        </Dialog>
                    </Paper>

                </div>
            </div>
        );
    }
}
export default withStyles(useStyles)(BillResultTable);
