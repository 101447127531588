import axios from 'axios';
import AuthenticationService from "./AuthenticationService";
import {isNotEmpty} from "../utils/Validator";
import {config} from "./ConfigService";

class BillSearchService {
    findBills(searchParams) {
        let url = `${config.API_URL}/billSearch/searchByAssorted`;

        return axios.post(url, searchParams.searchParams);
    }

    findMitchBills(searchParams) {
        let url = `${config.API_URL}/billSearch/mitchBillSearch`;

        return axios.post(url, searchParams);
    }

    findTinsForUser(userName) {
        let ftinUser = userName;
        let mUser = AuthenticationService.getMirrorUser();
        if(isNotEmpty(mUser)) {
            ftinUser = mUser;
        }

        let url = `${config.API_URL}/ftin/userFtins/${ftinUser}?activeFlag=true`;

        return axios.get(url);
    }

    /*
        /billSearch/findPaymentInfo
        Input:
        private String ftin;
        private String billControlNumber;

        Output:
        private BigDecimal paidAmount;
        private LocalDate paidDate;
        private String checkNumber;
    */
    findPaymentDetails(tin, bcn) {
        let url = `${config.API_URL}/billSearch/findPaymentInfo`;
        let searchParams =  {
            ftin: tin, 
            billControlNumber: bcn
        };

        return axios.post(url, searchParams);
    }

    searchBillPriceSheet(tin, billRefNumber) { 
        return axios.post(
            `${config.API_URL}/billSearch/searchBillPriceSheet `, 
            {billRefNumber},
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            } );
    }

    searchBillRefNubmer(tin, billRefNumber) {

        return axios.post(`${config.API_URL}/billSearch/searchBillReference`, {billRefNumber});
    }

    searchBillImage =  (dcn) => {
       return axios({
            url: `${config.API_URL}/billSearch/billImage/${dcn}` ,
            method: 'GET',
            responseType: 'blob'
        });

    }

    dummy(params) {
        return axios.post(`${config.API_URL}/public/billSearch/start`, {});
    }

    stub(paramName, value) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                console.log("setTimeout completed");
                if (value === true) {
                    resolve({ response: { data: { [paramName]: value } } });
                } else {
                    reject({ response: { data: { message: "Something Happened!" } } });
                }
            }, 3000);
        });
    }

    getCannedData(paramName, value) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (value === true) {
                    let response = {};
                    response.data = this.getRawBillList();
                    resolve(response);
//                    resolve({ response: { data: this.getRawBillList() } } );
                } else {
                    reject({ response: { data: "Something Happened!" } } );
                }
            }, 3000);
        });
    }

    getRawBillList = () => {
        let result = `
            [
                {
                    "beginServiceDate": "2019-04-25T23:28:56.782Z",
                    "billCharges": 138.75,
                    "billControlNumber": "2018896",
                    "billingId": "",
                    "billStatus": "In Progress",
                    "claimNumber": "",
                    "dateOfInjury": null,
                    "documentId": "2019151Z0011272",
                    "endServiceDate": "2019-04-26T23:28:56.782Z",
                    "facilityName": "OCCUPATIONAL HEALTH CENTERS OF",
                    "patientFirstName": "John",
                    "patientLastName": "Doe",
                    "payerId": "962501268",
                    "payerName": "Other",
                    "physicianFirstName": "DANIEL P",
                    "physicianLastName": "GODIO",
                    "recommendedAllowance": 138.75
                },
                {
                    "beginServiceDate": "2019-05-25T23:28:56.782Z",
                    "billCharges": 100.72,
                    "billControlNumber": "2018921",
                    "billingId": "",
                    "billStatus": "In Progress",
                    "claimNumber": "",
                    "dateOfInjury": null,
                    "documentId": "2019151Z0011271",
                    "endServiceDate": "2019-05-26T23:28:56.782Z",
                    "facilityName": "OCCUPATIONAL HEALTH CENTERS OF",
                    "patientFirstName": "Jane",
                    "patientLastName": "Doe",
                    "payerId": "962501268",
                    "payerName": "Other",
                    "physicianFirstName": "CHARLTON S",
                    "physicianLastName": "MCNAIR",
                    "recommendedAllowance": 0
                },
                {
                    "beginServiceDate": "2019-03-25T23:28:56.782Z",
                    "billCharges": 177.24,
                    "billControlNumber": "2019414",
                    "billingId": "",
                    "billStatus": "In Progress",
                    "claimNumber": "STN018478",
                    "dateOfInjury": "2018-08-09",
                    "documentId": "2019151Z0014416",
                    "endServiceDate": "2019-03-26T23:28:56.782Z",
                    "facilityName": "OCCUPATIONAL HEALTH CENTERS OF",
                    "patientFirstName": "DNKEVWAVTAF",
                    "patientLastName": "XOQZIF",
                    "payerId": "962526299",
                    "payerName": "Other",
                    "physicianFirstName": "DANIEL P",
                    "physicianLastName": "GODIO",
                    "recommendedAllowance": 177.24
                }
            ]
        `;

        return JSON.parse(result);
    }
}

export default new BillSearchService();
