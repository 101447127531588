import * as React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  ListItemText,
  FormHelperText,
} from "@material-ui/core";

import Checkbox from "@material-ui/core/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// itemList is what they can choose from
// value is an array that holds the selected values
// itemList can be [{value: "value1", label: "label1"}, {value: "value2", label: "label2"},]
// itemList can be ["a", "b", "c"] where the individual items will be used as the value and label
function MultiSelectCheckbox({
  helperText,
  id = null,
  name,
  label = "Field Label",
  error = false,
  itemList = [],
  onChange,
  maxSelectedCount = 1000,
  value = [],
}) {
  const handleChange = (event) => {
    let itemsChecked = event.target.value;

    if (itemsChecked.length <= maxSelectedCount) {
      onChange(event);
    }
  };

  return (
    <FormControl variant="outlined" style={{ width: "100%" }} error={error}>
      <InputLabel
        shrink
        style={{
          backgroundColor: "white",
          paddingLeft: "4px",
          paddingRight: "4px",
        }}
        required
      >
        {label}
      </InputLabel>
      <Select
        labelId={`${id}-checkbox-label`}
        id={id}
        name={name}
        multiple
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {itemList.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <Checkbox checked={value.includes(item.value)} />
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default MultiSelectCheckbox;
