class BillSearchPermissionChecker {
  hasPermission(currentUser, path) {
    if (!currentUser) {
      return false;
    }

    return true;
  }
}

export default new BillSearchPermissionChecker();
