class ClientListPermissionChecker {
  hasPermission(currentUser, path) {
    if (!currentUser) {
      return false;
    }

    let { affiliationCd, typeCd: userTypeCd } = currentUser;
    let isAllowed = false;

    if (
      userTypeCd === "IRAD" ||
      userTypeCd === "ISAD" ||
      (affiliationCd === "OFC_STAFF" && (userTypeCd === "PRAD" || userTypeCd === "PRUS"))
    ) {
      isAllowed = true;
    }

    return isAllowed;
  }
}

export default new ClientListPermissionChecker();
