import React, { Component, Fragment } from 'react'
import { NavLink } from "react-router-dom";
import { Button } from '@material-ui/core';
import AcceptanceAgreeDlg from '../AcceptanceAgreeDlg'
import Hidden from '@material-ui/core/Hidden';

import './MainMenu.css';



const style = {
    menuButton: {
        color: "#ffffff",
        '&:hover': {
            textDecoration: 'underline'
        }
    }
  };

class MainMenu extends Component {

    navigateToClientList = () => {
        this.props.history.push('/wcpp/ClientList');
    }

    componentDidMount() {

    }

    getPortalMenu = () => {
        return(
            <div>
                          <Button
            style={style.menuButton}
            className="mainMenuButton"
            variant="text"
            component={NavLink}
            to="/wcpp/billSearch"
          >
            Bill Search
          </Button>


                <Button style={style.menuButton} className="mainMenuButton" variant="text"component={NavLink} to="/wcpp/ClaimSearch">Claim Search</Button>
								
                <AcceptanceAgreeDlg nav={this.navigateToClientList} />
                {/* <Button style={style.menuButton} className="mainMenuButton" variant="text" component={NavLink} to="/wcpp/FeeSchedule">PPO Fee Schedule</Button> */ }      

                <Hidden xlDown={this.shouldShowTinUserMenu() }>
                    <Button style={style.menuButton} className="mainMenuButton" variant="text" component={NavLink}
                            to="/wcpp/ManageUsers">Manage Users</Button>

                    <Button style={style.menuButton} className="mainMenuButton" variant="text" component={NavLink}
                            to='/wcpp/ManageTins'>Manage TINs</Button>
                </Hidden>
                <Button style={style.menuButton} className="mainMenuButton" variant="text" component={NavLink}
                        to='/wcpp/Demographics/DemographicSearch'>Provider Demographics</Button>
                <Button style={style.menuButton} className="mainMenuButton" variant="text" component={NavLink}
                        to='/wcpp/ProviderResources'>Provider Resources</Button>


            </div>
        );

    }

    shouldShowTinUserMenu = () => {
        if(this.props.isMirror) {
            return this.props.mirrorUserTypeCd === 'PRUS';
        } else {
            return this.props.typeCd === 'PRUS';
        }
    }

    getAdminPortalMenu = () => {
        return(
            <div>
                <Button style={style.menuButton} className="mainMenuButton" variant="text" component={NavLink} to="/wcpp/ClaimSearch">Claim Search</Button>
                <Button style={style.menuButton} className="mainMenuButton" variant="text" component={NavLink} to='/wcpp/ProviderResources'>Provider Resources</Button>
                <Button style={style.menuButton} className="mainMenuButton" variant="text" component={NavLink} to='/wcpp/UserSearch'>User Search</Button>
                <Hidden xlDown={this.props.typeCd === 'IRAD'}>
                    <Button style={style.menuButton} className="mainMenuButton" variant="text" component={NavLink} to='/wcpp/AddUser'>ADD USER</Button>
                    <Button style={style.menuButton} className="mainMenuButton" variant="text" component={NavLink} to='/wcpp/Notifications'>Notifications</Button>
                </Hidden>

            </div>
        );

    }

    getMenu = () => {

      if(this.props.isAdminPortal && !this.props.isMirror) {
           return this.getAdminPortalMenu();
       } else {
           return this.getPortalMenu();
       }
    }

    render() {
        return (
            <Fragment>

                <div className='main-menu'>
                    {this.getMenu()}
                </div>
            </Fragment>
        );
    }
}

export default MainMenu;
