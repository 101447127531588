import React, { useState } from "react";
import styles from "./ContentToggle.module.css";

const ContentToggle = ({ moreLabel, lessLabel, className, children, style }) => {
  const [showContent, setShowContent] = useState(false);

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  return (    
    <div className={styles.contentToggle} style={style}>
      <button
        onClick={toggleContent}
        className={`${styles.linkStyle} ${className}`}
      >
        {showContent ? <><span>{lessLabel}</span> &#11206;</> : <><span>{moreLabel}</span> &#11208;</>}
      </button>

      {showContent && children}
    </div>
  );
};

export default ContentToggle;
