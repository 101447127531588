import React, {Component, Fragment} from 'react'
import {withStyles} from "@material-ui/core/styles";
import './ProviderResources.css'
import ProviderResourcesTable from '../ProviderResourcesTable';
import Grid from "@material-ui/core/Grid";
import {  TextField, MenuItem, } from "@material-ui/core";
//import axios from 'axios';
import AuthenticationService from "../../Services/AuthenticationService";


const styles = theme => ({
    root: {
        width: '100%',
    },
    button: {
        margin: theme.spacing.unit,    
        cursor: 'pointer',
        color: '#0f197d'
    },
    input: {
      display: 'none',
    },
    
});
window.analytics ={pageName:'Provider Resources'};
class ProviderResources extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            resources: [],filteredUsers: [],
            errorMsg: '',         
            successmessage: '',
            categories: [],
            selectedCategory: 'Select All',
            categorisedDocs: []
        }

    }

    setfilter = (filtered) => {
        console.log(`in fitler set state`)
        this.setState({filteredUsers: filtered})

    }

    componentDidMount() {
        this.getUser();
        this.loadCategories();
    }

   /*  componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedCategory !== this.state.selectedCategory) {
            this.getFilteredItemsForCategory(this.state.selectedCategory);
        }
    } */

   
    getUser = ()=> {
        let currentComp = this;
          //AuthenticationService.getProviderResources()
          //http://localhost:3000/aem/api/assets/wcpp/provider-resources/provider-resources.json
          //http://localhost:3000/provider-resources.json
          fetch('/aem/api/assets/wcpp/provider-resources/provider-resources.json',
            {
                headers : { 
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                 }
              }
          )
            .then((response) => {
                if(response.status === 200) {
                    console.log(response);
                    return response.json();
                    // if(response.data !== 'undefined' && response.data.entities !== 'undefined'){
                    //     currentComp.setState({resources: response.data.entities, filteredUsers: response.data.entities})                    
                    // }
                }              
            })
            .then((jsn)=>{
                if(jsn !== 'undefined' && jsn.entities !== 'undefined'){
                    currentComp.setState({resources: jsn.entities, categorisedDocs: jsn.entities, filteredUsers: jsn.entities})                    
                } 
            })
            .catch(error => {
                console.log("Error in fetching: provider-resources.json ");
                console.log(error); 
        })
    }


    loadCategories() {
        const categoriesList = [];
        categoriesList.push({
            value: 'NOT',
            label: 'Notifications'
        });
         categoriesList.push({
                value: 'PR',
                label: 'Press Releases'
            });

            categoriesList.push({
                value: 'PM',
                label: 'Provider Manuals'
            });
            
            categoriesList.push({
                value: 'RN',
                label: 'Release Notes'
            });
            categoriesList.push({
                value: 'TRN',
                label: 'Training'
            });
            
            
            this.setState({ categories: categoriesList });
            return;
        }

        handleCategoryChange = (event) => {
            const target = event.target;
            const value = target.value;
            const name = target.name;
            
            this.setState({
                [name]: value
            });
           
           
            this.setState({selectedCategory:value});
            this.getFilteredItemsForCategory(value) ;
           
          };

          
            getFilteredItemsForCategory(value) {
                const items = this.state.resources;
                let getFilteredItemsForCategory = []
                if(value === 'Select All'){
                    getFilteredItemsForCategory= items;
                }else{
                    getFilteredItemsForCategory= items.filter(item => item.properties.category === value);
                }
                this.setState({categorisedDocs: getFilteredItemsForCategory, filteredUsers: getFilteredItemsForCategory});
            
          }

    render() {
       // const filteredItems = this.getFilteredItems();
        return(
          
            <Fragment>

                <div className='App-content Provider-resources '>
                    <div><h1 className='App-page-label' style={{display:'inline-block'}}>Provider Resources</h1>
                      </div>                      
                    <div className='App-error App-font' aria-live='polite'>{this.state.errorMsg}</div>
                    <div className='App-success App-font' aria-live='polite'>{this.state.successmessage}</div> 
                    <Grid item style={{width:'50%', textAlign:'left'}}>
                            <TextField name="category" id="category" label="Document Category"
                                //error={isNotEmpty(this.state.accountTypeError)} helperText={this.state.accountTypeError}
                                defaultValue="Select All"
                                select
                                value={this.state.selectedCategory}
                                onChange={this.handleCategoryChange}
                                fullWidth
                                required
                            >
                                 <MenuItem key={'Select All'} value={'Select All'}>
                                        {'Select All'}
                                    </MenuItem>
                                {this.state.categories.map(type => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    <div style={{paddingTop:'.5rem', fontSize: '13px'}}>   
                    <a className='App-link' 
                           href='https://www.coventrywcs.com'
                           aria-label="Opens Coventry Workers' Comp website in a new window" target="_blank" rel="noopener noreferrer">
                           Visit the Coventry Workers' Comp website </a> for extra information about Coventry.</div>             
                   <ProviderResourcesTable resources={this.state.categorisedDocs} filteredUsers={this.state.filteredUsers} setFilter={this.setfilter}
                                    />
                </div>
            </Fragment>

        );

    }
}
export default withStyles(styles)(ProviderResources);