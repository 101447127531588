import React, { Component, Fragment } from 'react'
import './App.css'
import './Enlyte.css'
import { Header, Footer } from './Components/Layouts'
import {BrowserRouter,  Route, Switch, Redirect} from 'react-router-dom'
import ProviderHome from './Components/Pages/ProviderHome'
import Notifications from './Components/Pages/Notifications'
import AuthenticatedRoute from './Components/AuthenticatedRoute'
import LoginComponent from './Components/LoginComponent'
import LogoutComponent from './Components/LogoutComponent'
import MainMenu from './Components/MainMenu/MainMenu';
import ClientList from './Components/Pages/ClientList'
import BillSearch from './Components/Pages/BillSearch/BillSearch'
import ManageUsers from "./Components/Pages/ManageUsers"
import ClaimSearch from "./Components/Pages/ClaimSearch"
import ManageTins from "./Components/Pages/ManageTins"
import News from "./Components/Pages/News"
import Registration from "./Components/Pages/Registration/Registration";
import ConfirmRegistration from "./Components/Pages/Registration/ConfirmRegistration";
import ResetPassword from "./Components/Pages/ResetPassword/ResetPassword";
import ConfirmPasswordReset from "./Components/Pages/ResetPassword/ConfirmPasswordReset";
import UserProfile from "./Components/Pages/UserProfile";
import EditProfile from "./Components/Pages/EditProfile";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from './AetnaTheme.js';
import ManageTinsForUser from './Components/Pages/ManageTinsForUser';
import ForgotUsername from "./Components/ForgotUsername";
import AddUser from "./Components/Pages/AddUser";
import ManageUserAddUser from "./Components/Pages/ManageUserAddUser";
import AddUserManageTin from "./Components/Pages/AddUserManageTin";
import ConfirmAddUser from "./Components/Pages/ConfirmAddUser";
import ProviderResources from './Components/Pages/ProviderResources';
import AuthenticationService from "./Services/AuthenticationService";
import PageNotFound from "./Components/Pages/PageNotFound";
import LoginHeader from "./Components/Layouts/LoginHeader";
import UserSearch from "./Components/Pages/UserSearch";
import FeeSchedule from "./Components/Pages/FeeSchedule/FeeSchedule";
import ChangePassword from "./Components/Pages/ChangePassword";
import RegistrationComplete from "./Components/Pages/Registration/RegistrationComplete";
import ManageRecipients from "./Components/Pages/ManageRecipients";
import DemographicSearch from "./Components/Pages/Demographics/DemographicSearch";

import BillSearchPermissionChecker from "./utils/Security/BillSearchPermissionChecker";
import ClaimSearchPermissionChecker from "./utils/Security/ClaimSearchPermissionChecker";
import ClientListPermissionChecker from "./utils/Security/ClientListPermissionChecker";
import ManageTinsPermissionChecker from "./utils/Security/ManageTinsPermissionChecker";
import ManageUsersPermissionChecker from "./utils/Security/ManageUsersPermissionChecker";
import ProviderDemographicsPermissionChecker from "./utils/Security/ProviderDemographicsPermissionChecker";
import ProviderResourcesPermissionChecker from "./utils/Security/ProviderResourcesPermissionChecker";

class App extends Component {
    timeout;


    constructor(props) {
        super(props);
        this.state = { isScrollTop: false, isChangedPwd: false, adminUser: false,
                logout: false, user: null, isSubUser:true, isTimeOut: false, typeCd: '',
                mirrorUserNm: '',feeSchedRqstFiles:[], token: null, mirrorUsrNmDisplay:'',
            mirrorUser: null}
    }

    // currently called from the UserProfileDlg so main menu is updated based on the new
    // user properties
    updateUser = (user) => {
        this.setState({ user: user })
    }

    scrollToTop = () => {
        this.setState({ isScrollTop: true })
    }

    setChangedPwd = (changed) => {
        this.setState({ isChangedPwd: changed })
    }

    setUser = (user, token) => {
        window.chatUser = user;

        this.setState({user: user,
            isSubUser: user.typeCd === 'PRUS',
            typeCd: user.typeCd, adminUser: user.typeCd === 'ISAD'  || user.typeCd === 'IRAD',
            feeSchedRqstCount: user.feeSchedRqstsCnt,
            feeSchedRqstFiles: user.feeSchedRqsts, token: token});
        setTimeout(()=>this.getIdleTimeOut(), 5000);
    }

    setFeeSchedRqstCount = (count) => {

        this.setState({feeSchedRqstCount : count});
    }

    setFeeSchedRqstsFiles = (feeSchedRqsts) => {

        this.setState({feeSchedRqstFiles : feeSchedRqsts});
    }
    componentDidMount() {
        this.setChangedPwd(false);
        this.events = [
            'load',
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress'
        ];
        this.removeListeners();
        this.stopMirror();
    }
    removeListeners = () => {
        for (let i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
    }

    componentDidUpdate() {

        if (this.state.isScrollTop) {
            window.scrollTo(0, 0);
            this.setState({ isScrollTop: false })
        }
    }

    getIdleTimeOut = () => {
        AuthenticationService.getIdleTimeout()
            .then((response) => {
                this.setIdleTime( response.data);
            }).catch( (error) =>{
            this.setIdleTime(30);
            console.log('Get idle timeout error:' + error);
        })

    }

    setIdleTime = (idleTime) => {
        //   console.log(`start time ${new Date().toLocaleTimeString()} for time out of ${idleTime}`)
        this.timeout = idleTime * 60000;
        for (let i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }
        this.setTimeout();
    }

    clearTimeoutFunc = () => {
        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    }

    setLogout= () => {
        this.stopMirror();
        if(this.state.logout === true) {
            this.setState({logout:false, isTimeOut: true, user: null})
        } else {
            this.setState({user:null})
        }
        this.clearTimeoutFunc();
    }

    setTimeout = () => {
        this.logoutTimeout = setTimeout(this.logout, this.timeout);
    }
    resetTimeout = () => {
        //   console.log(`reset time out time ${new Date().toLocaleTimeString()} for time out of ${this.state.signOutTime/60000}`)
        this.clearTimeoutFunc();
        this.setTimeout();
    }

    logout = () => {
        this.removeListeners();
        this.clearTimeoutFunc();
        this.setState({logout: true})
        //  console.log(`logged out time ${new Date().toLocaleTimeString()} for time out of ${this.state.signOutTime}`)
    }

    timeOut = () => {

        const timeout = this.state.isTimeOut;
        this.setState({isTimeOut: false});
        return timeout;
    }

    showMenu = () => {
        if(this.state.user !== null) {
            return(<AuthenticatedRoute user={this.state.user} path='/*' render={(props) => <MainMenu {...props} user={this.state.user} isSubUser={this.state.isSubUser} 
            mirrorUser={this.state.mirrorUser} typeCd={this.state.typeCd} mirrorUserTypeCd={this.state.mirrorUserTypeCd}
            isAdminPortal={this.state.adminUser} isMirror={this.state.mirrorUserNm !== ''}/> }/>);
        }
    }

    showHeader = () => {
        if(this.state.user === null) {
            return(
                <Route path="/*" exact component={LoginHeader} />
            );
        } else {
            return(
                <Route path="/*" render={(props) =><Header {...props} updateUser={this.updateUser} user={this.state.user} mirrorUserNm={this.state.mirrorUserNm}
                                                           mirrorUsrNmDisplay={this.state.mirrorUsrNmDisplay} setLogout={this.setLogout} setMirror={this.setMirror} stopMirror={this.stopMirror}
                                                           logout={this.state.logout} feeSchedRqstCount={this.state.feeSchedRqstCount}
                                                           setFeeSchedRqstCount={this.setFeeSchedRqstCount} feeSchedRqstFiles = {this.state.feeSchedRqstFiles}
                                                           setFeeSchedRqstsFiles={this.setFeeSchedRqstsFiles}/>} />
            );
        }
    }

    stopMirror = () => {
        if(this.state.mirrorUserNm === '') {
            AuthenticationService.removeMirrorUser();
        } else {
            AuthenticationService.removeMirrorUser(this.state.mirrorUserNm, `${this.state.user.userFirstName} ${this.state.user.userLastName}`);
            this.setState({feeSchedRqstCount: null, feeSchedRqstFiles:null});
        }

        this.setState({mirrorUser: null, mirrorUserNm:'', mirrorUsrNmDisplay: ''});
    }

    setMirrorUsrNotifications = (mirrorUser) => {
        AuthenticationService.getUsrDtls(mirrorUser)
            .then((response) => {

                this.setState({
                    feeSchedRqstCount: response.data.feeSchedRqstsCnt,
                    feeSchedRqstFiles: response.data.feeSchedRqsts});

            }).catch(function (error) {
            console.log('Mirror user, get user error:' + error);
        })
    }

    setMirror = (mirrorUserNm, mirrorUser) => {
        this.setMirrorUsrNotifications(mirrorUser.userNm);

        AuthenticationService.setMirrorUser( mirrorUser.userNm, mirrorUserNm, `${this.state.user.userFirstName} ${this.state.user.userLastName}`);
        this.setState({mirrorUser: mirrorUser, mirrorUserNm: mirrorUser.userNm,mirrorUsrNmDisplay:mirrorUserNm});
    }

    getCurrentUser = () => {
        return this.props.mirrorUser ?? this.props.user;
    };
    
    render() {

        return (
            <Fragment>
                <BrowserRouter>
                    <MuiThemeProvider theme={theme}>
                        <div className='App' aria-live="polite">

                            {this.showHeader()}

                            <div style={{ height: '100%' }} >
                                {this.showMenu()}

                                <div className="main">

                                    <Switch>
                                        <Route path="/wcpp/authenticate" exact render={(props) =><LoginComponent {...props} setUser={this.setUser} setCode={this.setCode}/> } />
                                        <Route path="/wcpp/login" exact render={(props) =><LoginComponent {...props} setUser={this.setUser} timeOut={this.timeOut} />} />
                                        <Route path='/wcpp/registration' exact component={Registration} />
                                        <Route path='/wcpp/forgotusernm' exact component={ForgotUsername} />
                                        <Route path='/wcpp/forgotpassword' exact component={ResetPassword} />
                                        <Route path='/wcpp/resetPassword' exact component={ResetPassword} />
                                        <Route path='/wcpp/confirmPasswordReset' exact component={ConfirmPasswordReset} />
                                        <Route path='/wcpp/confirmRegistration' exact component={ConfirmRegistration} />
                                        <Route path='/wcpp/registrationComplete' exact component={RegistrationComplete} />
                                        <Route path='/wcpp/confirmAddUser' exact component={ConfirmAddUser} />
                                        <AuthenticatedRoute path='/wcpp/providerResources' exact permissionChecker={ProviderResourcesPermissionChecker} user={this.state.mirrorUser ?? this.state.user} component={ProviderResources} />
                                        <AuthenticatedRoute path="/wcpp/logout" exact component={LogoutComponent} />
                                        <AuthenticatedRoute path='/wcpp/home' render={() => <ProviderHome />}/>
                                        <AuthenticatedRoute path='/wcpp/EditProfile' component={EditProfile} />
                                        <AuthenticatedRoute path='/wcpp/ClientList' permissionChecker={ClientListPermissionChecker} user={this.state.mirrorUser ?? this.state.user} component={ClientList} />
                                        <AuthenticatedRoute path='/wcpp/Notifications' render={(props) =><Notifications {...props} userNm={this.state.user.userNm}/>}/>
                                        <AuthenticatedRoute path='/wcpp/ManageRecipients' render={(props) =><ManageRecipients{...props} typeCD={this.state.typeCd}/>}/>
                                        <AuthenticatedRoute path='/wcpp/FeeSchedule' render={(props) =><FeeSchedule {...props} feeschedrqstCount={this.state.feeSchedRqstCount} setFeeSchedRqstCount={this.setFeeSchedRqstCount} setFeeSchedRqstsFiles={this.setFeeSchedRqstsFiles} token={this.state.token}/>}/>
										<AuthenticatedRoute path='/wcpp/userSearch' render={(props) =><UserSearch {...props} typeCD={this.state.typeCd} lgUser={this.state.user} setMirror={this.setMirror}/>}/>
                                        <AuthenticatedRoute path='/wcpp/billSearch' permissionChecker={BillSearchPermissionChecker} user={this.state.mirrorUser ?? this.state.user} component={BillSearch} />
                                        <AuthenticatedRoute path='/wcpp/Demographics/DemographicSearch' permissionChecker={ProviderDemographicsPermissionChecker} user={this.state.mirrorUser ?? this.state.user} component={DemographicSearch}/>
                                        <AuthenticatedRoute path='/wcpp/ClaimSearch' permissionChecker={ClaimSearchPermissionChecker} user={this.state.mirrorUser ?? this.state.user} component={ClaimSearch} />
                                        <AuthenticatedRoute path='/wcpp/ManageTins' permissionChecker={ManageTinsPermissionChecker} user={this.state.mirrorUser ?? this.state.user} component={ManageTins} />
                                        <AuthenticatedRoute path='/wcpp/ManageUsers' permissionChecker={ManageUsersPermissionChecker} user={this.state.mirrorUser ?? this.state.user} render={(props) =><ManageUsers {...props} lgUser={this.state.user}/>} />
                                        <AuthenticatedRoute path='/wcpp/ManageTinsForUser' component={ManageTinsForUser} />
                                        <AuthenticatedRoute path='/wcpp/ChangePassword' render={() => <div><ChangePassword isChangedPwd={this.state.isChangedPwd} setChangedPwd={this.setChangedPwd} /></div>} />
                                        <AuthenticatedRoute path='/wcpp/UserProfile' component={UserProfile} />
                                        <AuthenticatedRoute path='/wcpp/News' component={News} />
                                        <AuthenticatedRoute path='/wcpp/AddUser' render={(props) =><AddUser {...props} isAdmin={this.state.adminUser}/>}/>
                                        <AuthenticatedRoute path='/wcpp/ManageUserAddUser' render={(props) =><ManageUserAddUser {...props} />}/>
                                        <AuthenticatedRoute path='/wcpp/AddUserManageTin' component={AddUserManageTin} />
                                        <AuthenticatedRoute path='/wcpp/PageNotFound' component={PageNotFound}/>
                                        <AuthenticatedRoute path='*' >
                                            <Redirect to="/wcpp/PageNotFound"  />
                                        </AuthenticatedRoute>
                                    </Switch>
                                </div>
                            </div>
                            <Footer scrollUp={this.scrollToTop} />
                        </div>
                    </MuiThemeProvider>
                </BrowserRouter>
            </Fragment>
        );
    }
}

export default App;
